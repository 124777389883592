<template>

    <div id="ConsultaAtendimentos">

        <h2 class="h2 align-right">
            <span class="float-left">Consulta de atendimentos</span>
            <a href="#" class="bt bt-voltar" style="font-size: .8rem" @click="voltarAtendente">
                Voltar
            </a>
        </h2>

        <h3 class="h3">Filtros</h3>
        <table class="parvus-table-base grid condensed vertical-separator filtros">
            <tbody>
                <tr>
                    <td width="300"><b>Cliente</b></td>
                    <td width="300"><b>Situação</b></td>
                    <td width="175"><b>Data entrada</b></td>
                    <td width="220"><b>Sistema</b></td>
                    <td width="300"><b>Revenda de cadastro</b></td>
                    <td width="100"><b>Transferência</b></td>
                </tr>
                <tr>
                    <td>
                        <input type="text" id="clientes" name="clientes" />
                    </td>
                    <td>
                        <select2 name="situacao" id="situacao">
                            <option value="">--- Selecione</option>
                            <option v-for="item in situacao" :value="item.sigla" :key="item.sigla">
                                {{ item.sigla }} - {{ item.nome }}
                            </option>
                        </select2>
                    </td>
                    <td>
                        <input type="text" class="data" name="dataAtendInicio" id="dataAtendInicio"
                               :value="formataData(filtros.dataAtendInicio)" style="padding-right: 5px;" />
                        &nbsp;&nbsp;à&nbsp;&nbsp;
                        <input type="text" class="data" name="dataAtendFim" id="dataAtendFim"
                               :value="formataData(filtros.dataAtendFim)" style="padding-right: 5px;" />
                    </td>
                    <td>
                        <select2 name="sistema" id="sistema">
                            <option value="">--- Selecione</option>
                            <option v-for="item in sistemas" :value="item.sistemaId" :key="item.sistemaId">
                                {{ item.sistemaId }} - {{ item.nome }}
                            </option>
                        </select2>
                    </td>
                    <td>
                        <select2 name="revenda_pai" id="revenda_pai">
                            <option value="">--- Selecione</option>
                            <option v-for="item in revendas" :value="item.representanteId" :key="item.representanteId">
                                {{ item.representanteId }} - {{ item.nome }}
                            </option>
                        </select2>
                    </td>
                    <td>
                        <select2 name="transferencia" id="transferencia">
                            <option value="">--- Selecione</option>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                        </select2>
                    </td>
                </tr>
                <tr>
                    <td><b>Usuário atendido</b></td>
                    <td><b>Atendente</b></td>
                    <td><b>Tempo de espera</b></td>
                    <td><b>Tempo de atendimento</b></td>
                    <td><b>Revenda de atendimento</b></td>
                    <td><b>Status</b></td>
                </tr>
                <tr>
                    <td>
                        <input type="text" id="usuario" name="usuario" />
                    </td>
                    <td>
                        <input type="text" id="atendentes" name="atendentes" />
                    </td>
                    <td>
                        <input type="text" class="hora" name="tempo_espera_inicio" id="tempo_espera_inicio" />
                        &nbsp;&nbsp;à&nbsp;&nbsp;
                        <input type="text" class="hora" name="tempo_espera_fim" id="tempo_espera_fim" />
                    </td>
                    <td>
                        <input type="text" class="hora" name="tempo_atend_inicio" id="tempo_atend_inicio" />
                        &nbsp;&nbsp;à&nbsp;&nbsp;
                        <input type="text" class="hora" name="tempo_atend_fim" id="tempo_atend_fim" />
                    </td>
                    <td>
                        <select2 name="revenda_chat" id="revenda_chat">
                            <option value="">--- Selecione</option>
                            <option v-for="item in revendas" :value="item.representanteId" :key="item.representanteId">
                                {{ item.representanteId }} - {{ item.nome }}
                            </option>
                        </select2>
                    </td>
                    <td>
                        <select2 name="encerrou" id="encerrou">
                            <option value="">--- Selecione</option>
                            <option value="Cliente">Encerrado Cliente</option>
                            <option value="Atendente">Encerrado Atendente</option>
                            <option value="Não atendido">Não atendido</option>
                            <option value="Em atendimento">Em atendimento</option>
                            <option value="Fila">Fila</option>
                        </select2>
                    </td>
                </tr>
                <tr>
                    <td colspan="6" class="align-center">
                        <button type="submit" class="bt" @click="buscaAtendimentos(true)">Filtrar</button>
                        <button type="reset" class="bt" @click="removeFiltros">Retirar filtros</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <paginacao
            :quantidade="paginacao.quantidade"
            :total="paginacao.total"
            :paginas="paginacao.paginas"
            :atual="paginacao.atual"
            :limiteName="'limite1'"
            :paginaName="'pagina1'"
        ></paginacao>

        <p class="aviso msgFiltro" v-if="Object.keys(filtros).length > 0">
            Os registros exibidos abaixo estão sendo filtrados.
            <a href="javascript:void(0);" class="parvus-table-filtro-remove" @click="removeFiltros">
                Clique para retirar todos os filtros.
            </a>
        </p>

        <table class="parvus-table-base grid condensed vertical-separator">
            <thead>
                <tr>
                    <th class="center parvus-table-coluna-ordena" width="50" style="cursor: pointer;"
                        @click="mudaOrdem('situacao')"
                    >
                        <a href="javascript:;">S</a>&nbsp;
                        <span class="undefined icon-seta-baixo"
                              v-show="order.campo === 'situacao' && order.ordem === -1">
                        </span>
                        <span class="undefined icon-seta-cima"
                              v-show="order.campo === 'situacao' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-left parvus-table-coluna-ordena" width="200" style="cursor: pointer;"
                        @click="mudaOrdem('atendido_id')"
                    >
                        <a href="javascript:;">Cliente</a>&nbsp;
                        <span class="undefined icon-seta-baixo"
                              v-show="order.campo === 'atendido_id' && order.ordem === -1">
                        </span>
                        <span class="undefined icon-seta-cima"
                              v-show="order.campo === 'atendido_id' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-left parvus-table-coluna-ordena" width="300" style="cursor: pointer;"
                        @click="mudaOrdem('contato')"
                    >
                        <a href="javascript:;">Usuário Atendido</a>&nbsp;
                        <span class="undefined icon-seta-baixo"
                              v-show="order.campo === 'contato' && order.ordem === -1">
                        </span>
                        <span class="undefined icon-seta-cima"
                              v-show="order.campo === 'contato' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-left parvus-table-coluna-ordena" width="100" style="cursor: pointer;"
                        @click="mudaOrdem('sistema_id')"
                    >
                        <a href="javascript:;">Sistema</a>&nbsp;
                        <span class="undefined icon-seta-baixo"
                              v-show="order.campo === 'sistema_id' && order.ordem === -1">
                        </span>
                        <span class="undefined icon-seta-cima"
                              v-show="order.campo === 'sistema_id' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-left parvus-table-coluna-ordena" width="100" style="cursor: pointer;"
                        @click="mudaOrdem('transferido')"
                    >
                        <a href="javascript:;">Transferência</a>&nbsp;
                        <span class="undefined icon-seta-baixo"
                              v-show="order.campo === 'transferido' && order.ordem === -1">
                        </span>
                        <span class="undefined icon-seta-cima"
                              v-show="order.campo === 'transferido' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-left parvus-table-coluna-ordena" width="150" style="cursor: pointer;"
                        @click="mudaOrdem('revenda_pai')"
                    >
                        <a href="javascript:;">Revenda de cadastro</a>&nbsp;
                        <span class="undefined icon-seta-baixo"
                              v-show="order.campo === 'revenda_pai' && order.ordem === -1">
                        </span>
                        <span class="undefined icon-seta-cima"
                              v-show="order.campo === 'revenda_pai' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-left parvus-table-coluna-ordena" width="150" style="cursor: pointer;"
                        @click="mudaOrdem('revenda_chat')"
                    >
                        <a href="javascript:;">Revenda de atendimento</a>&nbsp;
                        <span class="undefined icon-seta-baixo"
                              v-show="order.campo === 'revenda_chat' && order.ordem === -1">
                        </span>
                        <span class="undefined icon-seta-cima"
                              v-show="order.campo === 'revenda_chat' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-center parvus-table-coluna-ordena" width="75" style="cursor: pointer;"
                        @click="mudaOrdem('data_entrada')"
                    >
                        <a href="javascript:;">Data/hora Entrada</a>&nbsp;
                        <span class="undefined icon-seta-baixo"
                              v-show="order.campo === 'data_entrada' && order.ordem === -1">
                        </span>
                        <span class="undefined icon-seta-cima"
                              v-show="order.campo === 'data_entrada' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-center parvus-table-coluna-ordena" width="75" style="cursor: pointer;"
                        @click="mudaOrdem('tempo_espera')"
                    >
                        <a href="javascript:;">Tempo de espera</a>&nbsp;
                        <span class="undefined icon-seta-baixo"
                              v-show="order.campo === 'tempo_espera' && order.ordem === -1">
                        </span>
                        <span class="undefined icon-seta-cima"
                              v-show="order.campo === 'tempo_espera' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-left parvus-table-coluna-ordena" width="100" style="cursor: pointer;"
                        @click="mudaOrdem('atendente_nome')"
                    >
                        <a href="javascript:;">Atendente</a>&nbsp;
                        <span class="undefined icon-seta-baixo"
                              v-show="order.campo === 'atendente_nome' && order.ordem === -1">
                        </span>
                        <span class="undefined icon-seta-cima"
                              v-show="order.campo === 'atendente_nome' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-left parvus-table-coluna-ordena" width="50">
                        Status
                    </th>
                    <th class="align-center parvus-table-coluna-ordena" width="75" style="cursor: pointer;"
                        @click="mudaOrdem('data_atendido')"
                    >
                        <a href="javascript:;">Data/hora Atendido</a>&nbsp;
                        <span class="undefined icon-seta-baixo"
                              v-show="order.campo === 'data_atendido' && order.ordem === -1">
                        </span>
                        <span class="undefined icon-seta-cima"
                              v-show="order.campo === 'data_atendido' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-center parvus-table-coluna-ordena" width="75" style="cursor: pointer;"
                        @click="mudaOrdem('tempo_atendimento')"
                    >
                        <a href="javascript:;">Tempo atendimento</a>&nbsp;
                        <span class="undefined icon-seta-baixo"
                              v-show="order.campo === 'tempo_atendimento' && order.ordem === -1">
                        </span>
                        <span class="undefined icon-seta-cima"
                              v-show="order.campo === 'tempo_atendimento' && order.ordem === 1">
                        </span>
                    </th>
                    <th class="align-center parvus-table-coluna-ordena" width="30">
                        VER
                    </th>
                    <th class="align-center parvus-table-coluna-ordena" width="30">
                        INFO
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr :class="'id_'+item.atendimento_id" v-for="item in resultados" :key="'id_'+item.atendimento_id">
                    <td align="center" oldtitle="Situação" title="">{{ item.situacao }}</td>
                    <td align="left" oldtitle="Cliente" title="">{{ item.atendido_nome }}</td>
                    <td align="left" oldtitle="Usuário Atendido" title="">{{ item.contato }}</td>
                    <td align="left" oldtitle="Sistema" title="">{{ item.sistema_id+' - '+item.sistema_nome }}</td>
                    <td align="left" oldtitle="Transferência" title="">{{ item.transferido ? 'Sim' : 'Não' }}</td>
                    <td align="left" oldtitle="Revenda de cadastro" title="">{{ item.revenda_pai_nome }}</td>
                    <td align="left" oldtitle="Revenda de atendimento" title="">{{ item.revenda_chat_nome }}</td>
                    <td align="center" oldtitle="Data/hora entrada" title="">
                        {{ formataData(item.data_entrada, true) }}
                    </td>
                    <td align="center" oldtitle="Tempo de espera" title="">{{ calculaTempo(item, 'espera') }}</td>
                    <td align="left" oldtitle="Atendente" title="">{{ item.atendente_nome }}</td>
                    <td align="left" oldtitle="Quem encerrou" title="">{{ quemEncerrou(item) }}</td>
                    <td align="center" oldtitle="Data/hora atendido" title="">
                        {{ formataData(item.data_atendido, true) }}
                    </td>
                    <td align="center" oldtitle="Tempo de atendimento" title="">{{ calculaTempo(item, 'atendimento') }}</td>
                    <td align="center" oldtitle="Ver" title="">
                        <a href="javascript:void(0);" class="acao" oldtitle="Ver conversa" title=""
                           v-show="item.data_atendido && item.encerrado" @click="verConversa(item.atendimento_id)"
                        >
                            <span class="icon-visualizar"></span><span class="visuallyhidden">Ver conversa</span>
                        </a>
                    </td>
                    <td align="center" oldtitle="Info" title="">
                        <span class="icon-info tooltip" style="font-size: 1.2rem"
                              :title="'Retornos: '+(!item.contador ? 0 : item.contador)+'\n'+item.user_agent+'\nEncerrado por: '+item.encerrado_nome+'\nDevolvido: '+(item.devolvido ? 'Sim - '+item.devolvido_por : 'Não')+'\nObs atendente: '+(typeof item.obs_atendente != 'undefined' ? item.obs_atendente : '')"
                              v-show="item.user_agent">
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>

        <p class="aviso msgFiltro" v-if="Object.keys(filtros).length > 0">
            Os registros exibidos abaixo estão sendo filtrados.
            <a href="javascript:void(0);" class="parvus-table-filtro-remove" @click="removeFiltros">
                Clique para retirar todos os filtros.
            </a>
        </p>

        <paginacao
                :quantidade="paginacao.quantidade"
                :total="paginacao.total"
                :paginas="paginacao.paginas"
                :atual="paginacao.atual"
                :limiteName="'limite2'"
                :paginaName="'pagina2'"
        ></paginacao>

    </div>

</template>

<script>
    import Select2 from './Select2.vue';
    import Paginacao from './Paginacao.vue';

    export default {
        name: 'ConsultaAtendimentos',
        data() {
            let d = new Date();
            let dAtual = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59);

            d.setDate(d.getDate()-3)

            let dMenos3 = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 3, 0, 0);

            return {
                filtros: {
                    dataAtendInicio: dMenos3,
                    dataAtendFim: dAtual,
                },
                clientes: {},
                revendas: {},
                situacao: {},
                sistemas: {},
                atendentes: {},
                resultados: {},
                paginacao: {
                    paginas: 1,
                    quantidade: 50,
                    total: 0,
                    atual: 1,
                },
                order: {
                    campo: 'data_entrada',
                    ordem: -1
                }
            }
        },
        components: {
            Select2,
            Paginacao
        },
        methods: {
            voltarAtendente: function () {
                this.$router.push('/atendimento');
            },
            msToTime: function (ms) {
                if (isNaN(ms) || Number(ms) < 1000) return null;
                return ms_to_time(ms, true);
            },
            formataData: function (data, hora = false) {
                return !data ? '' : exibe_data(data, hora);
            },
            alteraFiltro: async function (data) {
                //Filtro quantidade de registros por página
                if (typeof data.quantidade != 'undefined') {
                    this.filtros.skip = 0;
                    this.filtros.limit = data.quantidade;
                    this.paginacao.atual = 1;
                }
                //Filtro pagina
                if (typeof data.pagina != 'undefined') {
                    this.filtros.skip = (data.pagina-1) * this.paginacao.quantidade;
                    this.paginacao.atual = data.pagina;
                }
                //Filtro cliente/atendido
                if (typeof data.atendido != 'undefined') {
                    if (data.atendido != '') {
                        let split = data.atendido.split(' - ');
                        this.filtros.atendidoId = Number(split[0]);
                    } else {
                        delete this.filtros.atendidoId;
                    }
                }
                //Filtro situação
                if (typeof data.situacao != 'undefined') {
                    if (data.situacao != ''){
                        this.filtros.situacao = data.situacao;
                    } else {
                        delete this.filtros.situacao;
                    }
                }
                //Filtro atendente
                if (typeof data.atendente != 'undefined') {
                    if (data.atendente != '') {
                        let split = data.atendente.split(' - ');
                        this.filtros.atendenteId = Number(split[0]);
                    } else {
                        delete this.filtros.atendenteId;
                    }
                }
                //Filtro usuário/contato
                if (typeof data.contato != 'undefined') {
                    if (data.contato != '') {
                        this.filtros.contato = data.contato;
                    } else {
                        delete this.filtros.contato;
                    }
                }
                //Sistema
                if (typeof data.sistema != 'undefined') {
                    if (data.sistema != ''){
                        this.filtros.sistemaId = Number(data.sistema);
                    } else {
                        delete this.filtros.sistemaId;
                    }
                }
                //Revenda de cadastro
                if (typeof data.revendaPai != 'undefined') {
                    let split = data.revendaPai.split(' - ');
                    let id    = Number(split[0]);

                    if (id > 0){
                        this.filtros.revendaPai = Number(split[0]);
                    } else {
                        delete this.filtros.revendaPai;
                    }
                }
                //Revenda de atendimento
                if (typeof data.revendaChat != 'undefined') {
                    let split = data.revendaChat.split(' - ');
                    let id    = Number(split[0]);

                    if (id > 0){
                        this.filtros.revendaChat = Number(split[0]);
                    } else {
                        delete this.filtros.revendaChat;
                    }
                }
                //Filtro transferência
                if (typeof data.transferencia != 'undefined') {
                    if (data.transferencia != '') {
                        this.filtros.transferido = data.transferencia;
                    } else {
                        delete this.filtros.transferido;
                    }
                }
                //Filtro encerrou
                if (typeof data.encerrado != 'undefined') {
                    delete this.filtros.pessoaIdEncerrado;
                    delete this.filtros.dataAtendido;

                    if (data.encerrado) {
                        this.filtros.encerrado = data.encerrado;
                    } else {
                        delete this.filtros.encerrado;
                    }
                }
                //Filtro data atendimento inicial
                if (typeof data.dataAtendInicio != 'undefined') {
                    if (data.dataAtendInicio != '') {
                        let exData = data.dataAtendInicio.split('/');
                        let dataDb = exData[2]+'-'+exData[1]+'-'+exData[0];
                        this.filtros.dataAtendInicio = new Date(dataDb+'T03:00:00Z');
                    } else {
                        delete this.filtros.dataAtendInicio;
                    }
                }
                //Filtro data atendimento final
                if (typeof data.dataAtendFim != 'undefined') {
                    if (data.dataAtendFim != '') {
                        let exData = data.dataAtendFim.split('/');
                        let dataDb = exData[2]+'-'+exData[1]+'-'+exData[0];
                        this.filtros.dataAtendFim = new Date(dataDb+'T23:59:59Z');
                    } else {
                        delete this.filtros.dataAtendFim;
                    }
                }
                //Filtro tempo espera inicio
                if (typeof data.tempoEsperaInicio != 'undefined') {
                    if (data.tempoEsperaInicio != '') {
                        let exHora = data.tempoEsperaInicio.split(':');
                        let horaMs = ((Number(exHora[0]) * 60) * 60) * 1000;
                        let minMs  = (Number(exHora[1]) * 60) * 1000;
                        this.filtros.tempoEsperaInicio = horaMs + minMs;
                    } else {
                        delete this.filtros.tempoEsperaInicio;
                    }
                }
                //Filtro tempo espera final
                if (typeof data.tempoEsperaFim != 'undefined') {
                    if (data.tempoEsperaFim != '') {
                        let exHora = data.tempoEsperaFim.split(':');
                        let horaMs = ((Number(exHora[0]) * 60) * 60) * 1000;
                        let minMs  = ((Number(exHora[1]) + 1) * 60) * 1000;
                        this.filtros.tempoEsperaFim = horaMs + minMs;
                    } else {
                        delete this.filtros.tempoEsperaFim;
                    }
                }
                //Filtro tempo espera inicio
                if (typeof data.tempoAtendInicio != 'undefined') {
                    if (data.tempoAtendInicio != '') {
                        let exHora = data.tempoAtendInicio.split(':');
                        let horaMs = ((Number(exHora[0]) * 60) * 60) * 1000;
                        let minMs  = (Number(exHora[1]) * 60) * 1000;
                        this.filtros.tempoAtendInicio = horaMs + minMs;
                    } else {
                        delete this.filtros.tempoAtendInicio;
                    }
                }
                //Filtro tempo espera final
                if (typeof data.tempoAtendFim != 'undefined') {
                    if (data.tempoAtendFim != '') {
                        let exHora = data.tempoAtendFim.split(':');
                        let horaMs = ((Number(exHora[0]) * 60) * 60) * 1000;
                        let minMs  = ((Number(exHora[1]) + 1) * 60) * 1000;
                        this.filtros.tempoAtendFim = horaMs + minMs;
                    } else {
                        delete this.filtros.tempoAtendFim;
                    }
                }
            },
            removeFiltros: function () {
                this.filtros = {};

                $('.filtros input').val('');
                $('.filtros select').val('').trigger('change');

                this.buscaAtendimentos();
            },
            mudaOrdem: function (campo) {
                if (this.order.campo == campo) {
                    if (this.order.ordem === 1) {
                        this.order.ordem = -1;
                    } else {
                        this.order.ordem = 1;
                    }
                } else {
                    this.order.campo = campo;
                    this.order.ordem = 1;
                }

                this.buscaAtendimentos();
            },
            buscaAtendimentos: function (reset = false) {
                let vm = this;
                let auth = this.$cookies.get('auth');

                this.$socket.emit('busca-atendimentos', {
                        filtros: this.filtros,
                        order: this.order,
                        revenda: auth.dados.representanteOriginalId
                    },
                    function (res) {
                        vm.resultados = res.results;
                        vm.paginacao.total = res.total;
                        vm.paginacao.paginas = Math.ceil(vm.paginacao.total/vm.paginacao.quantidade);

                        if (!vm.paginacao.paginas) vm.paginacao.paginas = 1;

                        if (reset) vm.paginacao.atual = 1;
                    });

                delete this.filtros.skip;
            },
            verConversa: function (atendimentoId) {
                let vm = this;
                let viewconversa = new vm.$viewconversa({
                    propsData: {atendimentoId}
                });

                viewconversa.$mount();

                $('#box-conversa').show();

                document.getElementById('box-view-conversa').appendChild( viewconversa.$el );
            },
            quemEncerrou: function (chatRow) {
                if (!chatRow.encerrado) {
                    if (!chatRow.data_atendido) {
                        return 'Fila';
                    } else {
                        return 'Em atendimento';
                    }
                } else {
                    if (!chatRow.atendente_id || chatRow.pessoa_id_encerrado === 0) {
                        return 'Não atendido';
                    } else if (chatRow.pessoa_id_encerrado > 0 &&
                        chatRow.atendido_id != chatRow.pessoa_id_encerrado
                    ) {
                        if (chatRow.encerrado_inatividade) {
                            return 'Encerrado Atendente (inatividade)';
                        } else {
                            return 'Encerrado Atendente';
                        }
                    } else {
                        if (chatRow.encerrado_botao) {
                            return 'Encerrado Cliente (botão fechar)';
                        } else if (chatRow.encerrado_inatividade) {
                            return 'Encerrado Cliente (inatividade)';
                        }

                        return 'Encerrado Cliente';
                    }
                }
            },
            calculaTempo: function (chatRow, tipo) {
                switch (tipo)
                {
                    default: return '';

                    case 'espera':
                        if (chatRow.data_entrada && !chatRow.tempo_espera) {
                            let time = 0;

                            if (!chatRow.data_encerrado) {
                                time = new Date() - new Date(chatRow.data_entrada);
                            } else {
                                if (!chatRow.data_atendido) {
                                    time = new Date(chatRow.data_encerrado) - new Date(chatRow.data_entrada);
                                } else {
                                    time = new Date(chatRow.data_atendido) - new Date(chatRow.data_entrada);
                                }
                            }

                            return ms_to_time(time);
                        }

                        if (chatRow.tempo_espera) return ms_to_time(chatRow.tempo_espera);

                        break;

                    case 'atendimento':
                        if (chatRow.data_entrada && chatRow.data_atendido && !chatRow.tempo_atendimento) {
                            let time = 0;

                            if (!chatRow.data_encerrado) {
                                time = new Date() - new Date(chatRow.data_atendido);
                            } else {
                                time = new Date(chatRow.data_encerrado) - new Date(chatRow.data_atendido);
                            }

                            return ms_to_time(time);
                        }

                        if (chatRow.tempo_atendimento) return ms_to_time(chatRow.tempo_atendimento);

                        break;
                }
            }
        },
        mounted() {
            let vm = this
            let auth = this.$cookies.get('auth');

            this.buscaAtendimentos();

            $('.hora').mask('99:99');
            $('.data').mask('99/99/9999');

            this.$socket.emit('busca-select-revenda', auth.dados.representanteOriginalId,
                function (res) {
                    vm.revendas = res;
                }
            );
            this.$socket.emit('busca-sistema', '', function (res) {
                vm.sistemas = res;
            });
            this.$socket.emit('busca-situacao', '', function (res) {
                vm.situacao = res;
            });

            this.$socket.emit('busca-pessoa', {
                    revendaId: auth.dados.representanteOriginalId
                },
                function (res) {
                    let clientes   = [];
                    let atendentes = [];

                    _.forEach(res, function(val) {
                        clientes.push(`${val.pessoaId} - ${val.nomeCompleto}`);
                        if (val.pessoaId >= 80000) {
                            atendentes.push(`${val.pessoaId} - ${val.nomeCompleto}`);
                        }
                    });

                    $('#clientes').autocomplete({ source: clientes });
                    $('#atendentes').autocomplete({ source: atendentes });
                }
            );

            //Filtros
            $('#clientes').change(function () {
                vm.alteraFiltro({ atendido: $(this).val() });
            });
            $('#atendentes').change(function () {
                vm.alteraFiltro({ atendente: $(this).val() });
            });
            $('#situacao').change(function () {
                vm.alteraFiltro({ situacao: $(this).val() });
            });
            $('#sistema').change(function () {
                vm.alteraFiltro({ sistema: $(this).val() });
            });
            $('#revenda_pai').change(function () {
                vm.alteraFiltro({ revendaPai: $(this).val() });
            });
            $('#revenda_chat').change(function () {
                vm.alteraFiltro({ revendaChat: $(this).val() });
            });
            $('#transferencia').change(function () {
                vm.alteraFiltro({ transferencia: $(this).val() });
            });
            $('#encerrou').change(function () {
                vm.alteraFiltro({ encerrado: $(this).val() });
            });
            $('#usuario').change(function () {
                vm.alteraFiltro({ contato: $(this).val() });
            });
            $('#dataAtendInicio').change(function () {
                vm.alteraFiltro({ dataAtendInicio: $(this).val() });
            });
            $('#dataAtendFim').change(function () {
                vm.alteraFiltro({ dataAtendFim: $(this).val() });
            });
            $('#tempo_espera_inicio').change(function () {
                vm.alteraFiltro({ tempoEsperaInicio: $(this).val() });
            });
            $('#tempo_espera_fim').change(function () {
                vm.alteraFiltro({ tempoEsperaFim: $(this).val() });
            });
            $('#tempo_atend_inicio').change(function () {
                vm.alteraFiltro({ tempoAtendInicio: $(this).val() });
            });
            $('#tempo_atend_fim').change(function () {
                vm.alteraFiltro({ tempoAtendFim: $(this).val() });
            });

            $('input, select').keyup(function (e) {
                if (e.which == 13 || e.keyCode == 13) {
                    vm.buscaAtendimentos(true);
                }
            });
        }
    }
</script>

<style>
    .principal {padding-bottom: 15px !important;}
    button[type="reset"] { color: #fff; }
</style>
