<template>

    <div id="box-totais" v-if="consultaTotaisChat" style="display: none; width: 100%; max-width: 1200px; padding: 15px;">
        <h1 class="h1">Totais</h1>
        <div class="b-xs-12"
                v-if="totais.emEspera && (totais.emEspera.cliente + totais.emEspera.revenda) > 0"
        >
            <b class="titulo-totais" @click="showHide('totaisEspera')" id="totaisEspera">
                Fila de espera - por sistema (clique para abrir/fechar):
            </b>
            <div class="box-totais-tabela" style="display: none">
                <table class="parvus-table-base grid condensed vertical-separator"
                    v-for="(r, revenda_id) in totais.emEspera.porRevenda" :key="revenda_id"
                >
                    <thead>
                        <tr>
                            <th style="text-align: right">
                                {{ revenda(revenda_id) }}
                            </th>
                            <th width="15%" v-show="canal == 'A'">
                                Clientes: {{ r.cliente }}
                            </th>
                            <th width="15%" v-show="canal == 'A'">
                                Revendas: {{ r.revenda }}
                            </th>
                            <th width="15%">
                                Total: {{ r.cliente + r.revenda }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s, sistema_id) in r.porSistema" :key="sistema_id"
                        >
                            <td style="text-align: right">
                                {{ sistema(sistema_id) }}
                            </td>
                            <td width="15%" v-show="canal == 'A'">
                                {{ s.cliente }}
                            </td>
                            <td width="15%" v-show="canal == 'A'">
                                {{ s.revenda }}
                            </td>
                            <td width="15%">
                                {{ s.cliente + s.revenda }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br class="clear" />
        </div>
        <div class="b-xs-12"
                v-if="totais.emAtendimento && (totais.emAtendimento.cliente + totais.emAtendimento.revenda) > 0"
        >
            <b class="titulo-totais" @click="showHide('totaisEmAtend')" id="totaisEmAtend">
                Em atendimento - por sistema (clique para abrir/fechar):
            </b>
            <div class="box-totais-tabela" style="display: none">
                <table class="parvus-table-base grid condensed vertical-separator"
                    v-for="(r, revenda_id) in totais.emAtendimento.porRevenda" :key="revenda_id"
                >
                    <thead>
                        <tr>
                            <th style="text-align: right">
                                {{ revenda(revenda_id) }}
                            </th>
                            <th width="15%" v-show="canal == 'A'">
                                Clientes: {{ r.cliente }}
                            </th>
                            <th width="15%" v-show="canal == 'A'">
                                Revendas: {{ r.revenda }}
                            </th>
                            <th width="15%">
                                Total: {{
                                    (['A','C'].indexOf(canal) >= 0 ? r.cliente : 0) +
                                    (['A','P'].indexOf(canal) >= 0 ? r.revenda : 0)
                                }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s, sistema_id) in r.porSistema" :key="sistema_id">
                            <td style="text-align: right">
                                {{ sistema(sistema_id) }}
                            </td>
                            <td width="15%" v-show="canal == 'A'">
                                {{ s.cliente }}
                            </td>
                            <td width="15%" v-show="canal == 'A'">
                                {{ s.revenda }}
                            </td>
                            <td width="15%">
                                {{ s.cliente + s.revenda }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br class="clear" />
        </div>
        <div class="b-xs-12"
                v-if="totais.emAtendimentoAtendente && Object.keys(totais.emAtendimentoAtendente).length > 0"
                id="boxTotaisAtendentes"
        >
            <b class="titulo-totais" @click="showHide('totaisAtend')" id="totaisAtend">
                Atendentes em atendimento (clique para abrir/fechar):
            </b>
            <div class="box-totais-tabela atendentes" style="display: none">
                <span>
                    <b class="titulo-totais-revenda" @click="showHideAtend(0)" id="rev0">
                        Geral (clique para abrir/fechar):
                    </b>
                    <table class="parvus-table-base grid condensed vertical-separator" style="display: none">
                        <thead>
                            <tr>
                                <th style="text-align: right">
                                    {{ revenda(0) }}
                                </th>
                                <th width="15%" v-show="canal == 'A'">
                                    Clientes: {{ totais.emAtendimento.porRevenda['0'].cliente }}
                                </th>
                                <th width="15%" v-show="canal == 'A'">
                                    Revendas: {{ totais.emAtendimento.porRevenda['0'].revenda }}
                                </th>
                                <th width="15%">
                                    Total: {{
                                        (['A','C'].indexOf(canal) >= 0 ? totais.emAtendimento.porRevenda['0'].cliente : 0) +
                                        (['A','P'].indexOf(canal) >= 0 ? totais.emAtendimento.porRevenda['0'].revenda : 0)
                                    }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(s, sistemaId) in totais.emAtendimento.porRevenda['0'].porSistema" :key="sistemaId">
                                <td style="text-align: right">
                                    {{ sistema(sistemaId) }}
                                </td>
                                <td width="15%" v-show="canal == 'A'">
                                    {{ s.cliente }}
                                </td>
                                <td width="15%" v-show="canal == 'A'">
                                    {{ s.revenda }}
                                </td>
                                <td width="15%">
                                    {{ s.cliente + s.revenda }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </span>
                <br class="clear" />
                <span v-for="(aList, revendaId) in totais.emAtendimentoAtendente" :key="revendaId">
                    <b class="titulo-totais-revenda" @click="showHideAtend(revendaId)" :id="'rev'+revendaId">
                        {{ revenda(revendaId) }} (clique para abrir/fechar):
                    </b>
                    <table class="parvus-table-base grid condensed vertical-separator"
                        :style="{'display': revendaIdAtend === Number(revendaId) ? 'table':'none'}"
                        v-for="(a, atendKey) in aList" :key="atendKey"
                    >
                        <thead>
                            <tr>
                                <th style="text-align: right">
                                    {{ a.info.nome }}
                                </th>
                                <th width="15%" v-if="canal == 'A'">
                                    Clientes: {{ a.cliente }}
                                </th>
                                <th v-else>
                                    Tempo
                                </th>
                                <th width="15%" v-if="canal == 'A'">
                                    Revendas: {{ a.revenda }}
                                </th>
                                <th v-else>
                                    Revenda
                                </th>
                                <th width="15%">
                                    Total: {{ a.cliente + a.revenda }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="atendimento in a.porSistema" :key="atendimento.nome" class="em_atendimento">
                                <td style="text-align: right">
                                    {{ atendimento.nome }} - {{ atendimento.sistema }}
                                </td>
                                <td width="15%" class="datahora" :data-datetime="atendimento.dataAtendido">
                                    00:00
                                </td>
                                <td width="15%" colspan="2">
                                    {{ atendimento.revenda }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </span>
            </div>
        </div>
        <div class="b-xs-12" id="boxTotaisSemAtend"
            v-if="Object.keys(semAtenderPorRevenda).length > 0"
        >
            <b class="titulo-totais" @click="showHideSemAtendimento('totaisSemAtendimento', revendaAtendente)" id="totaisSemAtendimento" >Atendentes conectados Sem atendimentos em andamento (clique para abrir/fechar)</b>
            <div class="box-totais-tabela" style="display: none;">
                <span>
                    <b class="titulo-totais-revenda" id="atend0" @click="showHideSemAtend(0)">Geral (clique para abrir/fechar)</b>
                <table class="parvus-table-base grid condensed vertical-separator" style="display: none;">
                    <thead>
                        <tr>
                            <th style="width: 50%;">
                            </th>
                            
                            <th style="width: 30%;">
                            </th>
                            <th style="text-align: right; width: 10%;">
                                TOTAIS: {{ totaisSemAtender.geral.total }}
                            </th>
                        </tr>
                    </thead>
                    <tr>
                        <td style=" text-align: left; color: red; border-top: none; border-left: none; border-right: none; background-color: white;">
                            Total de atendentes conectados sem nenhum atendimento em andamento
                        </td>
                        <td style="width: 20%; text-align: right; border-left: none; border-right: none; background-color: white;">
                            <div style="font-weight: bold; color: red;" 
                                v-for="sistemaId in Object.keys(totaisSemAtender.geral)" :key="sistemaId"
                            >
                                <span v-if="sistemaId != 'total'" :title="hintAtendentesPorSistema(sistemaId)"> {{ sistema(sistemaId) }} </span>
                            </div>
                        </td>
                        <td style="width: 10%;">
                            <div style="font-weight: bold; color: red;" 
                                v-for="sistemaId in Object.keys(totaisSemAtender.geral)" :key="sistemaId"
                            >
                                <p v-if="sistemaId != 'total'" > {{ totaisSemAtender.geral[sistemaId] }} </p>
                            </div>
                        </td>
                    </tr>
                </table>
                </span>

                <span v-for="(revendaObj, revendaId) in this.semAtenderPorRevenda" :key="revendaId">
                        <b class="titulo-totais-revenda"  @click="showHideSemAtend(revendaId)" :id="'atend'+revendaId">
                            {{ revenda(revendaId) }} (clique para abrir/fechar):
                        </b>
                        <table class="parvus-table-base grid condensed vertical-separator" style="display: none;">
                            <thead>
                                <tr>
                                    <th style="width: 50%;">
                                    </th>
                                    
                                    <th style="width: 30%;">
                                    </th>
                                    <th style="text-align: right; width: 10%;">
                                        TOTAIS: {{ totaisSemAtender[revendaId].geral }}
                                    </th>
                                </tr>
                            </thead>
                            <tr>
                                <td style=" text-align: left; color: red; border-top: none; border-left: none; border-right: none; background-color: white;">
                                    Total de atendentes conectados sem nenhum atendimento em andamento
                                </td>
                                <td style="width: 20%; text-align: right; border-left: none; border-right: none; background-color: white;">
                                     <div style="font-weight: bold; color: red;" 
                                        v-for="sistemaId in Object.keys(totaisSemAtender[revendaId])" :key="sistemaId"
                                    >
                                        <p v-if="sistemaId != 'geral'"  :title="hintAtendentesPorRevenda(sistemaId, revendaId)"> {{ sistema(sistemaId) }} </p>
                                    </div>
                                </td>
                                <td style="width: 10%;">
                                     <div style="font-weight: bold; color: red;" 
                                        v-for="sistemaId in Object.keys(totaisSemAtender[revendaId])" :key="sistemaId"
                                    >
                                        <p v-if="sistemaId != 'geral'" > {{ totaisSemAtender[revendaId][sistemaId] }} </p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <table class="parvus-table-base grid condensed vertical-separator" style="display: none;"
                        v-for="(a, atendenteId) in revendaObj" :key="atendenteId">
                            <thead>
                                <tr>
                                    <th style="text-align: right; width: 60%;" @click="mostraDadosAtendente(atendenteId)">
                                        {{ a.pessoaId  }} - {{ a.nome }} - CONECTADO DESDE {{ exibe_data(a.logEntrada) }} <br> CANAIS SELECIONADOS: {{ exibeCanal(a.canal) }}
                                    </th>
                                    <th style="width: 20%;">
                                        TEMPO ÚLTIMOS 3 ATENDIMENTOS
                                    </th>
                                    <th style="width: 10%;">
                                        ATENDIDO ÀS
                                    </th>
                                </tr>
                            </thead>
                            <tbody :id="'atendente' + atendenteId" style="display: none;">
                                <tr></tr>
                                <tr>
                                    <td style="text-align: left; color: red; border-top: none; border-left: none; border-right: none; background-color: white;">
                                        Sistemas selecionados para atendimento:
                                    </td>
                                    <td  style="text-align: right; background-color: white; border-top: none; border-left: none; border-right: none;">
                                        <div style="font-weight: bold; color: red;" 
                                              v-for="sistemaId in a.sistemas" :key="sistemaId"
                                        >
                                            <p v-if="sistemaId" > {{ sistema(sistemaId) }} </p>
                                        </div>
                                    </td>
                                    <td style="background-color: white; border-top: none; border-left: none; border-right: none;"></td>
                                </tr>
                                <tr v-for="historico in a.historico" :key="historico.atendimentoId">
                                    <td style="text-align: right; border-left: 1px solid #d4d4d4;">
                                        {{ historico.atendidoId }} - {{ formataNomeExibicao(historico.nomeExibicao) }} - {{ historico.usuarioNome }} - {{ historico.sistemaNome }}
                                    </td>
                                    <td> {{ formatarTempoAtendimento(historico.tempoAtendimentoSegundos) }}</td>
                                    <td>
                                        {{  exibe_data(historico.dataEncerrado, true, true) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                </span>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    props: ['consultaTotaisChat', 'canal', 'revendaIdAtend'],
    data() {
        let auth = this.$cookies.get('auth');
        return {
            revendaAtendente: auth.dados.representanteOriginalId,
            aguardandoAtendimento: [],
            emAtendimento:[],
            totais: [],
            semAtender: {},
            semAtenderPorRevenda: [],
            semAtenderPorSistema: {},
            totaisSemAtender: {
                geral: {
                    total:0
                }
            }
        }
    },
    computed: {
        sistemasFiltrados(sistemas) {
            return sistemas.filter(sistemaId => sistemaId !== 0);
        }
    },
    methods: {
        exibe_data: function(data, horas = true, somenteHoras = false) { return exibe_data(data, horas, somenteHoras) },
        revenda: function (v) { return this.$parent.revenda(String(v)) },
        sistema: function (v) { return this.$parent.sistema(v) },
        showHide: function (box) {
            $('.titulo-totais:not(#'+box+')').parent().find('.box-totais-tabela').hide();
            $('#'+box).parent().find('.box-totais-tabela').toggle();
        },
        showHideSemAtendimento: function (box, revendaId) {
            $('.titulo-totais:not(#'+box+')').parent().find('.box-totais-tabela').hide();
            $('#'+box).parent().find('.box-totais-tabela').toggle();
            $('#atend'+revendaId).parent().find('table').toggle();
        },
        showHideAtend: function (revendaId) {
            $('#boxTotaisAtendentes .titulo-totais-revenda:not(#rev'+revendaId+')').parent().find('table').hide();
            $('#rev'+revendaId).parent().find('table').toggle();
        },
        showHideSemAtend: function (revendaId) {
            $('#boxTotaisSemAtend .titulo-totais-revenda:not(#atend'+revendaId+')').parent().find('table').hide();
            $('#atend'+revendaId).parent().find('table').toggle();
        },
        mostraDadosAtendente: function (atendenteId) {
            $("#atendente" + atendenteId).toggle();
        },
        carregaTotais: function () {
            this.aguardandoAtendimento = this.$parent.aguardandoAtendimento;
            this.emAtendimento = this.$parent.emAtendimento;

            let totais = {
                emEspera: {
                    cliente: 0,
                    revenda: 0,
                    porSistema: {}, // porSistema[sistema_id] = {revenda: 0, cliente: 0}
                    porRevenda: {
                        '0': {
                            'revenda': 0,
                            'cliente': 0,
                            'porSistema': {}
                        }
                    }, // porRevenda[revenda_id] = {revenda: 0, cliente: 0, porSistema[sistema_id]: {revenda: 0, cliente: 0}}
                },
                emAtendimento: {
                    cliente: 0,
                    revenda: 0,
                    porSistema: {}, // porSistema[sistema_id] = {revenda: 0, cliente: 0}
                    porRevenda: {
                        '0': {
                            'revenda': 0,
                            'cliente': 0,
                            'porSistema': {}
                        }
                    }, // porRevenda[revenda_id] = {revenda: 0, cliente: 0, porSistema[sistema_id]: {revenda: 0, cliente: 0}}
                },
                emAtendimentoAtendente: {
                    // emAtendimentoAtendente[revenda_id][pessoa_id] = {info: {}, contagem: 0, porSistema[sistema_id]: {revenda: 0, cliente: 0}}
                },
            };

            _.forEach(this.aguardandoAtendimento, function(chatRow) {
                let revendaReal = Number(chatRow.revenda_chat_nome.split(' - ')[0]);

                if (typeof totais.emEspera.porSistema[chatRow.sistema_original] == 'undefined') {
                    totais.emEspera.porSistema[chatRow.sistema_original] = {revenda: 0, cliente: 0}
                }

                if (typeof totais.emEspera.porRevenda[revendaReal] == 'undefined') {
                    totais.emEspera.porRevenda[revendaReal] = {revenda: 0, cliente: 0, porSistema: {}}
                }

                if (typeof totais.emEspera.porRevenda[revendaReal].porSistema[chatRow.sistema_original] == 'undefined') {
                    totais.emEspera.porRevenda[revendaReal].porSistema[chatRow.sistema_original] = {revenda: 0, cliente: 0}
                }

                if (chatRow.atendido_id < 80000) {
                    totais.emEspera.cliente++;
                    totais.emEspera.porSistema[chatRow.sistema_original].cliente++;
                    totais.emEspera.porRevenda[revendaReal].cliente++;
                    totais.emEspera.porRevenda[revendaReal].porSistema[chatRow.sistema_original].cliente++;
                } else {
                    totais.emEspera.revenda++;
                    totais.emEspera.porSistema[chatRow.sistema_original].revenda++;
                    totais.emEspera.porRevenda[revendaReal].revenda++;
                    totais.emEspera.porRevenda[revendaReal].porSistema[chatRow.sistema_original].revenda++;
                }

                // Geral
                if (typeof totais.emEspera.porRevenda['0'].porSistema[chatRow.sistema_original] == 'undefined') {
                    totais.emEspera.porRevenda['0'].porSistema[chatRow.sistema_original] = {revenda: 0, cliente: 0}
                }

                if (chatRow.atendido_id < 80000) {
                    totais.emEspera.porRevenda['0'].cliente++;
                    totais.emEspera.porRevenda['0'].porSistema[chatRow.sistema_original].cliente++;
                } else {
                    totais.emEspera.porRevenda['0'].revenda++;
                    totais.emEspera.porRevenda['0'].porSistema[chatRow.sistema_original].revenda++;
                }
            });

            _.forEach(this.emAtendimento, function(chatRow) {
                let revendaReal = Number(chatRow.revenda_chat_nome.split(' - ')[0]);

                if (typeof totais.emAtendimento.porSistema[chatRow.sistema_original] == 'undefined') {
                    totais.emAtendimento.porSistema[chatRow.sistema_original] = {revenda: 0, cliente: 0}
                }

                if (typeof totais.emAtendimento.porRevenda[revendaReal] == 'undefined') {
                    totais.emAtendimento.porRevenda[revendaReal] = {revenda: 0, cliente: 0, porSistema: {}}
                }

                if (typeof totais.emAtendimento.porRevenda[revendaReal].porSistema[chatRow.sistema_original] == 'undefined') {
                    totais.emAtendimento.porRevenda[revendaReal].porSistema[chatRow.sistema_original] = {revenda: 0, cliente: 0}
                }

                if (chatRow.atendido_id < 80000) {
                    totais.emAtendimento.cliente++;
                    totais.emAtendimento.porSistema[chatRow.sistema_original].cliente++;
                    totais.emAtendimento.porRevenda[revendaReal].cliente++;
                    totais.emAtendimento.porRevenda[revendaReal].porSistema[chatRow.sistema_original].cliente++;
                } else {
                    totais.emAtendimento.revenda++;
                    totais.emAtendimento.porSistema[chatRow.sistema_original].revenda++;
                    totais.emAtendimento.porRevenda[revendaReal].revenda++;
                    totais.emAtendimento.porRevenda[revendaReal].porSistema[chatRow.sistema_original].revenda++;
                }

                // Geral
                if (typeof totais.emAtendimento.porRevenda['0'].porSistema[chatRow.sistema_original] == 'undefined') {
                    totais.emAtendimento.porRevenda['0'].porSistema[chatRow.sistema_original] = {revenda: 0, cliente: 0}
                }

                if (chatRow.atendido_id < 80000) {
                    totais.emAtendimento.porRevenda['0'].cliente++;
                    totais.emAtendimento.porRevenda['0'].porSistema[chatRow.sistema_original].cliente++;
                } else {
                    totais.emAtendimento.porRevenda['0'].revenda++;
                    totais.emAtendimento.porRevenda['0'].porSistema[chatRow.sistema_original].revenda++;
                }

                // Listagem por atendente
                let atendenteKey = chatRow.atendente_nome.split(' - ');
                let revendaKey = atendenteKey[0];
                let pessoaKey = atendenteKey[2].toLowerCase().replace(/[^a-z]/g, '');

                if (typeof totais.emAtendimentoAtendente[revendaKey] == 'undefined') {
                    totais.emAtendimentoAtendente[revendaKey] = {};
                }

                if (typeof totais.emAtendimentoAtendente[revendaKey][pessoaKey] == 'undefined') {
                    totais.emAtendimentoAtendente[revendaKey][pessoaKey] = {
                        info: {
                            nome: chatRow.atendente_nome
                        },
                        cliente: 0,
                        revenda: 0,
                        porSistema: []
                    }
                }
                if (chatRow.atendido_id < 80000) {
                    let nomeExibe = chatRow.nome_exibicao.split(' - ');
                        nomeExibe = `${nomeExibe[0]} - ${nomeExibe[1]} - ${chatRow.usuario_nome}`;

                    totais.emAtendimentoAtendente[revendaKey][pessoaKey].cliente++;
                    totais.emAtendimentoAtendente[revendaKey][pessoaKey].porSistema.push({
                        pessoaId: chatRow.atendido_id,
                        nome: nomeExibe,
                        dataAtendido: chatRow.data_atendido,
                        revenda: chatRow.revenda_chat_nome,
                        sistema: chatRow.sistema_nome,
                    });
                } else {
                    totais.emAtendimentoAtendente[revendaKey][pessoaKey].revenda++;
                    totais.emAtendimentoAtendente[revendaKey][pessoaKey].porSistema.push({
                        pessoaId: chatRow.atendido_id,
                        nome: chatRow.nome_exibicao,
                        dataAtendido: chatRow.data_atendido,
                        revenda: chatRow.revenda_chat_nome,
                        sistema: chatRow.sistema_nome,
                    });
                }
            });

            // Ordenação da chave de em espera
            totais.emEspera.porSistema = Object.keys(totais.emEspera.porSistema)
                .sort()
                .reduce((finish, cVal) => {
                    finish[cVal] = totais.emEspera.porSistema[cVal];
                    return finish;
                }, {});

            totais.emEspera.porRevenda = Object.keys(totais.emEspera.porRevenda)
                .sort()
                .reduce((finish, cVal) => {
                    finish[cVal] = totais.emEspera.porRevenda[cVal];
                    return finish;
                }, {});

            // Ordenação da chave de em atendimento
            totais.emAtendimento.porSistema = Object.keys(totais.emAtendimento.porSistema)
                .sort()
                .reduce((finish, cVal) => {
                    finish[cVal] = totais.emAtendimento.porSistema[cVal];
                    return finish;
                }, {});
            totais.emAtendimento.porRevenda = Object.keys(totais.emAtendimento.porRevenda)
                .sort()
                .reduce((finish, cVal) => {
                    finish[cVal] = totais.emAtendimento.porRevenda[cVal];
                    return finish;
                }, {});

            // Ordenação da chave de atendimento por atendente revenda e nome
            totais.emAtendimentoAtendente = Object.keys(totais.emAtendimentoAtendente)
                .sort()
                .reduce((finish, revendaKey) => {
                    totais.emAtendimentoAtendente[revendaKey] = Object.keys(totais.emAtendimentoAtendente[revendaKey])
                        .sort()
                        .reduce((finish2, pessoaKey) => {
                            finish2[pessoaKey] = totais.emAtendimentoAtendente[revendaKey][pessoaKey];

                            // Ordena o por sistema pelo código do cliente
                            finish2[pessoaKey].porSistema = _.orderBy(
                                finish2[pessoaKey].porSistema,
                                ['pessoaId'], ['asc']
                            );

                            return finish2;
                        }, {});

                    finish[revendaKey] = totais.emAtendimentoAtendente[revendaKey];

                    return finish;
                }, {});

            this.$socket.emit(
                'busca-atendentes-sem-atendimento',
                {},
                (result) => {
                    this.verificaAtendentes(result);
                });
                this.totais = totais;
        },

        formataNomeExibicao: function(nomeExibicao) {
            return nomeExibicao.split(' - ')[1]
        },

        hintAtendentesPorSistema: function(sistemaId) {

            return this.semAtenderPorSistema[sistemaId]
            .map(pessoaId => {
                const pessoa = this.semAtender[pessoaId];
                if (!pessoa) return '';

                return [
                    pessoa.pessoaId + " - ",
                    pessoa.nome.toUpperCase(),
                    " - REVENDA",
                    pessoa.revendaOriginal,
                    "- CONECTADO DESDE: " + this.exibe_data(pessoa.logEntrada)
                ]
            })
            .sort((a, b) => {
                if (a[3] < b[3]) {
                    return -1;
                }
                if (a[3] > b[3]) {
                    return 1;
                }

                return a[1].localeCompare(b[1])
            })
            .map((array) => {
                return array.join(" ")
            })
            .filter(Boolean).join("\n")
        },

        hintAtendentesPorRevenda: function(sistemaId, revendaId) {

            return this.semAtenderPorSistema[sistemaId]
            .reduce((result, pessoaId) => {
                const pessoa = this.semAtender[pessoaId];
                if (!pessoa) return result;
                if (pessoa.revendaOriginal != revendaId) return result;


                result.push([
                    pessoa.pessoaId + " - ",
                    pessoa.nome.toUpperCase(),
                    " - REVENDA",
                    pessoa.revendaOriginal,
                    "- CONECTADO DESDE: " + this.exibe_data(pessoa.logEntrada)
                ])

                return result;
            },[])
            .sort((a, b) => {
                return a[1].localeCompare(b[1])
            })
            .map((array) => {
                return array.join(" ")
            })
            .filter(Boolean).join("\n")
        },

        verificaAtendentes: function (dados) {

            if (!dados) return;
            if (this.semAtenderPorRevenda) this.semAtenderPorRevenda = [];
            if (this.semAtenderPorSistema) this.semAtenderPorSistema = {};
            if (this.totaisSemAtender) {
                this.totaisSemAtender =  {
                    geral: {
                        total:0
                    }  
                } 
            }
            this.semAtenderPorRevenda = dados;
            this.semAtenderPorRevenda = this.semAtenderPorRevenda.reduce((acc, usuario) => {

                this.semAtender[usuario.pessoaId] = {
                    nome: usuario.nome,
                    logEntrada: usuario.logEntrada,
                    revendaOriginal: usuario.revendaOriginal,
                    canal: usuario.canal,
                    pessoaId: usuario.pessoaId

                };
                const revenda = usuario.revendaOriginal;

                if (!acc[revenda]) {
                    acc[revenda] = [];
                }

                if (!this.totaisSemAtender[revenda]) {
                    this.totaisSemAtender[revenda] = {
                        geral: 0
                    };
                }

                acc[revenda].push(usuario);

                usuario.sistemas.forEach(sistemaId => {

                    if (sistemaId == 127 || sistemaId == 9994) return;

                    if (!this.semAtenderPorSistema[sistemaId]) {
                        this.semAtenderPorSistema[sistemaId] = [];
                    }

                    this.semAtenderPorSistema[sistemaId].push(usuario.pessoaId); 

                    if (sistemaId == 0) return;
                
                    if (!this.totaisSemAtender[revenda][sistemaId]) {
                        this.totaisSemAtender[revenda][sistemaId] = 0;
                    }

                    this.totaisSemAtender[revenda][sistemaId]++;
                    this.totaisSemAtender[revenda].geral++;
                });
                return acc;
            }, {});

            for (const revenda in this.semAtenderPorRevenda) {
                this.semAtenderPorRevenda[revenda].sort((a, b) => a.nome.localeCompare(b.nome));
            }


            for(const revenda in this.totaisSemAtender) {

                if(revenda == 'geral') continue;


                for (const sistemaId in this.totaisSemAtender[revenda]) {

                    if (sistemaId == 'geral') continue;
                    
                    this.totaisSemAtender.geral.total += this.totaisSemAtender[revenda][sistemaId];
                    if (!this.totaisSemAtender.geral[sistemaId]) {
                        this.totaisSemAtender.geral[sistemaId] = 0;
                    }
                    this.totaisSemAtender.geral[sistemaId] += this.totaisSemAtender[revenda][sistemaId]; 
                }

            };
        },

        formatarTempoAtendimento: function(totalSeconds) {

            let hours = Math.floor(totalSeconds/1000 / 3600);
            let minutes = Math.floor((totalSeconds/1000 % 3600) / 60);
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        },

        exibeCanal: function(canal) {

            let canais= {
                A: "Clientes e Revendas",
                P: "Revendas",
                C: "Clientes"
            }

            canal = canal.map((item) => {
                return canais[item]
            })

            return canal.sort().join(' E ').toUpperCase();
           
        },
    },
}

</script>

<style>
    .titulo-totais-revenda {
        display: inline-block;
        width: 100%;
        background: #0d538c;
        padding: 5px;
        color: white;
        cursor: pointer;
        text-transform: uppercase;
    }
</style>